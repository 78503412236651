window.customEnv = {
  current_env: import.meta.env.VITE_APP_TITLE,
};

document.dispatchEvent(new CustomEvent("customEnvReady"));


import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import * as Sentry from "@sentry/vue";
import { currentEnv } from "./plugins/variables";

if (currentEnv === "synvest") {
  import("./styles/synvest_main.scss");
} else {
  import("./styles/main.scss");
}

import cookiePlugin from "@/plugins/cookie";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useConditionallyGTM } from "./plugins/useGTM";


const tooltipDirective = (app: any) => {
  app.directive("tooltip", {
    mounted(el: any, binding: any) {
      init(el, binding);
    },
    updated(el: any, binding: any) {
      init(el, binding);
    },
  });
};

function init(el: any, binding: any) {
  const position: any = binding.arg || "top";
  const tooltipText: any = binding.value || "Tooltip text";
  el.setAttribute("position", position);
  el.setAttribute("tooltip", tooltipText);
}

const directives = (app: any) => {
  tooltipDirective(app);
};

await store.dispatch("initialiseStore");

store.subscribe((mutation, state) => {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
      // Prevent files from being stored in localStorage
      if (["voorkant", "achterkant", "CLN"].includes(key)) {
        return;
      }

      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }

        seen.add(value);
      }

      return value;
    };
  };

  if (mutation.type === "addNotification") {
    // not store in localStorage
  } else {
    // Store the state object as a JSON string
    localStorage.setItem("store", JSON.stringify(state, getCircularReplacer()));
  }
});

const app = createApp(App).use(cookiePlugin).use(store).use(router).use(Donut);

useConditionallyGTM(app); /* Google Tag Manager */

directives(app);

// check if import.meta.env.FRONTEND_SENTRY_DSN and import.meta.env.FRONTEND_STAGING are set, otherwise do not initialize sentry
if (
  import.meta.env.VITE_FRONTEND_SENTRY_DSN &&
  import.meta.env.VITE_FRONTEND_STAGING
) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_FRONTEND_SENTRY_DSN,
    environment: import.meta.env.VITE_FRONTEND_STAGING,
  });
} else {
  console.info("Sentry not initialized, no DSN and ENV set.");
}

app.mount("#app");
