<template>
  <Loading
    isFullPage
    lockScroll
    loader="spinner"
    backgroundColor="#5E656C"
    :active="isLoading"
    :height="80"
    :width="80"
  >
    <slot>
      <div
        :class="
          text
            ? 'bg-white rounded-lg shadow-lg flex flex-col items-center gap-4 p-6 min-w-[15rem] text-sm md:text-base'
            : ''
        "
      >
        <div>
          {{ text }}
        </div>
        <svg
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          :stroke="env === 'trustus' ? '#00586E' : '#15395E'"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            transform="matrix(1, 0, 0, 1, 4.779637, 5.445412)"
          >
            <g transform="translate(1 1)" stroke-width="7">
              <circle
                stroke-opacity="1"
                stroke="#D9D9D9"
                cx="18"
                cy="18"
                r="18"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    </slot>
  </Loading>
</template>

<script setup lang="ts">
import Loading from "vue3-loading-overlay";
import { currentEnv } from "@/plugins/variables";

defineProps<{
  isLoading: boolean;
  text?: string;
}>();

const env = currentEnv;
</script>
